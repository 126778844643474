import { State } from "@/store/state";
import { Request } from "@/network/request";
import i18n from "@/i18n";
import toast from "@/plugins/toast";

export default class teacher {
  endPoint = "teacher";
  tableData = {
    data: [],
    autoCompleteData: [],
    headers: {
      id: {
        name: "id",
        keys: ["id"],
      },
      name: {
        name: "name",
        keys: ["name"],
      },

      user_name: {
        name: "username",
        keys: ["user", "username"],
      },
      email: {
        name: "email",
        keys: ["user", "email"],
      },
    },
    meta: {
      total: 1,
      per_page: 1,
      current_page: 1,
    },
  };
  getState = new State();
  getAutoState = new State();
  postState = new State();
  editState = new State();
  createState = new State();
  deleteState = new State();

  async get(page) {
    let request = new Request({
      method: "get",
      endpoint: `/${this.endPoint}`,
      params: page,
    });

    await this.getState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          this.tableData.data = value.teachers;
          this.tableData.meta = value.meta;
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }
  async getAutoCompleteData(page) {
    let request = new Request({
      method: "get",
      endpoint: `/${this.endPoint}`,
      params: page,
    });

    await this.getAutoState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          this.tableData.autoCompleteData = value.teachers;
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }
  async post(payload) {
    let request = new Request({
      method: "post",
      endpoint: `/${this.endPoint}`,
      data: payload,
    });

    await this.postState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          toast(
            i18n.t("create success"),
            { timeout: 4000, rtl: true },
            "sucess"
          );
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }
  async attachTeacherToEduClass(id, payload) {
    let request = new Request({
      method: "put",
      endpoint: `/class/attach/teacher/${id}`,
      data: payload,
    });

    await this.postState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          toast(
            i18n.t("create success"),
            { timeout: 4000, rtl: true },
            "sucess"
          );
        },
        onError: (error) => {
          toast(i18n.t(error.type), { timeout: 4000, rtl: true }, "error");
        },
      }
    );
  }
  async dettachTeacherFromEduClass(id, payload) {
    let request = new Request({
      method: "put",
      endpoint: `/class/detach/teacher/${id}`,
      data: payload,
    });

    await this.deleteState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          toast(
            i18n.t("deleted success"),
            { timeout: 4000, rtl: true },
            "warning"
          );
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }
  async edit(payload, id) {
    let request = new Request({
      method: "put",
      endpoint: `/${this.endPoint}/${id}`,
      data: payload,
    });

    await this.editState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          this.get();
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }
  async deleteItem(id) {
    let request = new Request({
      method: "delete",
      endpoint: `/${this.endPoint}/${id}`,
    });

    await this.deleteState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          this.get();
          toast(
            i18n.t("deleted success"),
            { timeout: 4000, rtl: true },
            "warning"
          );
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }
}

export const Users = new teacher();
